import React, { useEffect } from 'react';
  import '../styles/Friends.css';
  import { Helmet } from 'react-helmet';
  
  const Friends = () => {
    useEffect(() => {
      const handleScroll = () => {
        const floatingElements = document.querySelectorAll('.floating');
  
        floatingElements.forEach((element) => {
          const floatFactor = parseFloat(
            getComputedStyle(element).getPropertyValue('--float-factor')
          );
          const scrollPosition = window.scrollY;
  
          // Ajustamos el translateY basado en el scroll y el factor
          element.style.transform = `translateY(${scrollPosition * floatFactor}px)`;
        });
      };
  
      window.addEventListener('scroll', handleScroll);
  
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, []);

  return (
    <section className="Friends">

      <Helmet>
        <title>Making Friends - Friends, nuestros aliados </title>
        <meta name="description" content="Nuestras empresas aliadas que ofrecerán sus recompensas cada que termines un curso." />
      </Helmet>

      <section className="header-section">
        <h1 className="floating ff5 textprincipal"><span className="special-text">Haz crecer tu negocio</span>junto a futuros clientes.</h1>
        <p className="floating ff5"></p>
        <p className="floating ff2 principal_p">
        Únete a nuestra comunidad de empresas aliadas y ofrece recompensas especiales a nuestros usuarios. Crea relaciones duraderas y crezcan juntos en cada paso del camino.
        </p>
      </section>


      <section className="content-section">

      <p className="floating ff3 floating_p">
      <span className="special-text">Google</span> nos proporciona las herramientas tecnológicas para que nuestra app y nuestra IA funcionen correctamente.
  </p>
  <div className="image-container floating ff1">
    <img className="background-image" src="https://media.giphy.com/media/5t20FDjwknvByghP6O/giphy.gif" alt="Google Cloud For Startups" />
    <img className="logo-image" src="https://firebasestorage.googleapis.com/v0/b/making-friends-d7106.appspot.com/o/Logos%2FGloud%204startups%201.png?alt=media&token=adc458eb-ef73-497b-9c7e-1aa99cd94b0b" alt="Logo 1" />
  </div>

  <div className="image-container floating ff4">
    <img className="background-image" src="https://media.giphy.com/media/CzzAjIEGiXJGo/giphy.gif" alt="Vexi" />
    <img className="logo-image" src="https://firebasestorage.googleapis.com/v0/b/making-friends-d7106.appspot.com/o/Logos%2Fvexi-logo-white.png?alt=media&token=a14cb996-0b10-4311-8dbd-6cda47930af6" alt="Logo 2" />
  </div>

  <p className="floating ff10 floating_p">
  A través de nuestro curso de educación financiera, Vexi ofrece a nuestros usuarios su <span className="special-text">tarjeta de crédito como recompensa.</span>
  </p>
</section>


<section className="content-section">
<p className="floating ff5 floating_p">
  Nuestros usuarios aprenden a elaborar su protocolo de investigación y Tesicafé les ofrece un descuento para <span className="special-text">terminar su tesis.</span>
  </p>

  <div className="image-container floating ff2">
    <img className="background-image" src="https://media.giphy.com/media/46hpy8xB3MiHfruixn/giphy.gif?cid=ecf05e47uqj3fnoo4un6b8jxse0doe3t5ak9b8fjf52xihsq&ep=v1_gifs_related&rid=giphy.gif&ct=g" alt="Imagen 4" />
    <img className="logo-image" src="https://firebasestorage.googleapis.com/v0/b/making-friends-d7106.appspot.com/o/Logos%2FTesicaf%C3%A9Blanco.png?alt=media&token=f33ed7d4-f242-4e10-b896-44d9917011e9" alt="Logo 4" />
  </div>


  <div className="image-container floating ff7">
    <img className="background-image" src="https://media.giphy.com/media/3og0IU0ej5NI0XIDYc/giphy.gif?cid=ecf05e47emxd3xq5iid9y6epfeospro55le6rd29zvxdtwa1&ep=v1_gifs_related&rid=giphy.gif&ct=g" alt="Imagen 5" />
    <img className="logo-image" src="https://firebasestorage.googleapis.com/v0/b/making-friends-d7106.appspot.com/o/Logos%2FTuinLogoBlanco.png?alt=media&token=e393b543-bf4b-4a19-a074-bb63bea44347" alt="Logo 5" />
  </div>

  <p className="floating ff9 floating_p">
   Tuin ofrece a nuestros usuarios un descuento para elaborar un presupuesto para <span className="special-text">independizarse</span>, después de que terminen nuestro curso sobre ¿Cúando independizarme?.
  </p>
</section>


<section className="two-up">
  <div className="image-container floating ff2 XL">
    <img className="background-image-XL" src="https://media.giphy.com/media/kgPEpH4rMP33lt20pJ/giphy.gif?cid=ecf05e47bfwlxnwqn47jldwrfyddonqu7i3d9z3gqi8579n5&ep=v1_gifs_related&rid=giphy.gif&ct=g" alt="Imagen 6" />
    <img className="logo-image XLLogo" src="https://firebasestorage.googleapis.com/v0/b/making-friends-d7106.appspot.com/o/Logos%2FTeamMF.png?alt=media&token=6250e707-c9ed-4163-94d4-8ffcc79472cf" alt="Logo 6" />
  </div>

  <article className="floating reverse ff2">
    <h2 className='floating-mf'><span className="special-text">Making Friends</span> está hecho para crecer juntos.</h2>
    <p className='floating-mf-p'>
    Creamos alianzas donde todos nos ayudamos. Nos apoyamos mutuamente para crecer y avanzar juntos. Ser un Friend significa ser parte de un equipo.
    </p>
  </article>
</section>



    </section>
  );
};


export default Friends;
