import React from 'react';
import '../styles/Expertos.css';
import { Helmet } from 'react-helmet';

const Expertos = () => {
    return (
        <section className="Expertos">
<Helmet>
    <title>Making Friends - Directorio de Expertos en Vida Adulta</title>
    <meta name="description" content="Accede a servicios esenciales para la vida adulta de manera sencilla y confiable." />
</Helmet>


            <section className="Expertos_gif-section">
                <div className="Expertos_content-wrapper">
                    <img className="Expertos-avatar" src="https://firebasestorage.googleapis.com/v0/b/making-friends-d7106.appspot.com/o/Consumibles%2Favatar.png?alt=media&token=d57d9d42-519d-4ce8-ad39-0bc42c79598c" alt="Avatar de expertos" />
                    <h1 className="Expertos_h1">¿Quieres que más gente conozca tus <span className="special-text">servicios</span>?</h1>
                    <p className="home_p_principal">¡Nosotros te ayudamos a llegar a más clientes.</p>
                        <a href="https://makingfriends.typeform.com/to/CarOM6TS">
                            <button className="IA-INU-button">
                                Quiero ser un Experto
                                <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="button-icon" viewBox="0 0 24 24">
                                    <path d="M5 12h14M12 5l7 7-7 7"></path>
                                </svg>
                            </button>
                        </a>

                </div>
            </section>

            <div className="Expertos_content_pasos">
                <h1 className="Expertos_h1">Sigue estos pasos para convertirte en un <span className="special-text">Experto</span></h1>

            </div>

            <section className="Expertos-flujo">
                <div className="timeline">
                    <div className="timeline-item">
                        <div className="expertos-circle"></div>
                        <div className="expertos-text-izquierdo">
                            <h3 className='Expertos_h3-izquierdo'>Regístrate</h3>
                            <p className='Expertos_p'>Completa el formulario y te contactaremos en las siguientes horas.</p>
                        </div>
                    </div>

                    <div className="timeline-item">
                        <div className="expertos-circle1"></div>
                        <div className="expertos-text">
                            <h3 className='Expertos_h3'>Agenda de reunión virtual</h3>
                            <p className="Expertos_p">Una vez validado tu registro, coordina una videollamada para conocernos mejor y empezar a trabajar juntos.</p>
                        </div>
                    </div>

                    <div className="timeline-item">
                        <div className="expertos-circle2"></div>
                        <div className="expertos-text-izquierdo">
                            <h3 className='Expertos_h3-izquierdo'>Configura tu perfil profesional</h3>
                            <p className='Expertos_p'>Personaliza tu perfil destacando tus habilidades y servicios para que los clientes te encuentren fácilmente.</p>
                        </div>
                    </div>

                    <div className="timeline-item">
                        <div className="expertos-circle3"></div>
                        <div className="expertos-text-center">
                            <h3 className='Expertos_h3-center'>Recibe tus primeros clientes</h3>
                            <p className='Expertos_p'>Aumenta la visibilidad de tu marca entre usuarios comprometidos y motivados.</p>
                        </div>
                    </div>
                </div>
            </section>

            <div className="Expertos_content">
                <h1 className="Expertos_h1">Forma parte de nuestra <span className="special-text">red de Friends </span>y empieza a ganar usuarios.</h1>
                <p className="Expertos_p">Explora todas las categorías que tenemos para ti.</p>
            </div>

            <section className="empresa-section">
                <div className="empresa-grid">
                    <div className="empresa-item">
                        <div className="empresa-circle--legal"></div>
                        <div className="text-content">
                            <h3 className="empresa-title">Legal</h3>
                            <p className="empresa-description">Asesoría legal para dudas o incidentes que necesites.</p>
                        </div>
                    </div>

                    <div className="empresa-item">
                        <div className="empresa-circle--dental"></div>
                        <div className="text-content">
                            <h3 className="empresa-title">Salud Dental</h3>
                            <p className="empresa-description">Cuidado dental experto para sonrisas saludables.</p>
                        </div>
                    </div>

                    <div className="empresa-item">
                        <div className="empresa-circle--nutricional"></div>
                        <div className="text-content">
                            <h3 className="empresa-title">Nutricional</h3>
                            <p className="empresa-description">Mejora tu salud con asesoría nutricional personalizada.</p>
                        </div>
                    </div>

                    <div className="empresa-item">
                        <div className="empresa-circle--emprendimiento"></div>
                        <div className="text-content">
                            <h3 className="empresa-title">Emprendimiento</h3>
                            <p className="empresa-description">Aumenta la visibilidad de tu marca entre usuarios comprometidos y motivados.</p>
                        </div>
                    </div>

                    <div className="empresa-item">
                        <div className="empresa-circle--contable"></div>
                        <div className="text-content">
                            <h3 className="empresa-title">Contable</h3>
                            <p className="empresa-description">Asesoría contable para una gestión eficiente de tus finanzas.</p>
                        </div>
                    </div>

                    <div className="empresa-item">
                        <div className="empresa-circle--laboral"></div>
                        <div className="text-content">
                            <h3 className="empresa-title">Laboral</h3>
                            <p className="empresa-description">Asesoría laboral para solucionar cualquier duda o conflicto.</p>
                        </div>
                    </div>
                </div>
            </section>
        </section>
    );
};

export default Expertos;
