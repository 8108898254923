import React from 'react';
import '../styles/Home.css';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';


function Home() {
  return (
    <>
      <section className="home_block home_seccion-landing">
      <Helmet>
        <title>Making Friends - Aprende a ser adulto</title>
         <meta name="description" content="La app que te ayuda a navegar en el mundo adulto mientras ganas increíbles recompensas." />
        </Helmet>
        <figure className="home_item-parallax-media">
          <div className="home_area_circulos">
            <ul className="home_circulos">
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
            </ul>
          </div>
        </figure>
        <div className="home_item-parallax-content home_flex-container">
          <div className="home_centered-content">
            <h1 className="home_h1">¿Quién te enseña a ser <span className="special-text">adulto</span>?</h1>
            <p className="home_p_principal">Nadie, ¿Verdad? No te preocupes, estamos aquí para guiarte en este camino.</p>

            <div className="contenedor_home_btn">
              <h1 className="home_h2">Descarga la app</h1>
              <a href="https://play.google.com/store/apps/details?id=com.makingfriends" className="home-app-link">
                <img src="https://firebasestorage.googleapis.com/v0/b/making-friends-d7106.appspot.com/o/Consumibles%2Fplay-logo.png?alt=media&token=98ad72aa-e73d-48f9-a13a-4c1aa784f1c0" alt="Descargar en Google Play" />
              </a>
              <a href="https://apps.apple.com/mx/app/making-friends/id6502792919" className="home-app-link">
                <img src="https://firebasestorage.googleapis.com/v0/b/making-friends-d7106.appspot.com/o/Consumibles%2Fapple-store.png?alt=media&token=a608c945-b9ea-450a-9529-97f20ebc6693" alt="Descargar en App Store" />
              </a>
            </div>
          </div>
        </div>
      </section>

      <section className="adultos-section">
  <div className="adultos-section-text">
    <p className="home_p_adultos">La transición hacia la vida adulta es</p>
    <h1 className="home_adultos">Una transformación</h1>
    <span className="special-text">abrumadora y caótica.</span>
    <p className="home_p_adultos">Ante estos cambios, muchos sentimos que estamos fallando en varios aspectos. Y perdemos oportunidades para nuestro crecimiento.</p>
  </div>
  <div className="adultos-section-img">
    <img src="https://i.giphy.com/media/v1.Y2lkPTc5MGI3NjExc3BrdGUxOHYzdjVsaWcyZWhxMzZ2ZmNiOWFzaW1rY29rMTZvNXAwZSZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9cw/lvV5ue6mA1zECLyWyu/giphy.gif" alt="Tristeza" />
  </div>
</section>

<section className="app-section">
  <div className="app_centered-content">
    <h1 className="home_h1"><span className="special-text">Déjanos ayudarte</span>, somos:</h1>
    <p className="home_p_principal">La app que te ayuda a navegar en el mundo adulto.</p>
  </div>
  <div className="app_content-columns">
    <div className="app-section-img">
      <img src="https://firebasestorage.googleapis.com/v0/b/making-friends-d7106.appspot.com/o/Consumibles%2Fimage%204.png?alt=media&token=cb0013d2-abb4-4165-93c8-e9771418f5ae" alt="App Making Friends" />
    </div>
    <div className="app_right-content">
      <div className="app_dots_content">
        <div className="app_dots_content-img">
          <img src="https://i.giphy.com/media/v1.Y2lkPTc5MGI3NjExOGg1cDM4NDlrNTJ0bmFrb3drZ2x3MDl1cWY2dnZidThndDhhaGIzaCZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9cw/Kc1TPDRWHydtU8wDzJ/giphy.gif" alt="App Making Friends" />
        </div>
        <div>
          <h2 className="home_app">Aprende jugando</h2>
          <p className="home_p_app">Desde cómo funciona una tarjeta de crédito hasta saber cuando puedes independizarte.</p>
        </div>
      </div>

      <div className="app_dots_content">
        <div className="app_dots_content-img">
          <img src="https://i.giphy.com/media/v1.Y2lkPTc5MGI3NjExb3F6cTk3b3N2dmhiOHExaHY4dnU1NHAybDEyNmZqcGxiemx6MDdjYSZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9cw/AkovKBmyYumQ0EUOfR/giphy.gif" alt="App Making Friends" />
        </div>
        <div>
          <h2 className="home_app">Gana mientras aprendes</h2>
          <p className="home_p_app">Por cada lección que completes podrás desbloquear asesorías, descuentos o productos.</p>
        </div>
      </div>

      <div className="app_dots_content">
        <div className="app_dots_content-img">
          <img src="https://media.giphy.com/media/j0kuvws1a5Zd7r4olR/giphy.gif?cid=790b7611neg8kubt8zl6bnqw9y9kxog9au2ezfb5zt2hhxe6&ep=v1_stickers_search&rid=giphy.gif&ct=s" alt="App Making Friends" />
        </div>
        <div>
          <h2 className="home_app">Haz crecer tu marca</h2>
          <p className="home_p_app">Sé parte de nuestros aliados y empieza ganar clientes.</p>
        </div>
      </div>
    </div>
  </div>
</section>

<section className="servicios-section">
  <div className="servicios_centered-content">
    <h1 className="home_h1"><span className="special-text">Todo</span> lo que necesitas para ser <span className="special-text">adulto</span></h1>
  </div>

  <div className="servicios-grid-container">
    <div className="servicios-grid-item">
      <div className="circle"></div>
      <div className="content-left">
      <h2 className="home_h2_servicios-left">Nuestros cursos</h2>
      <p className="home_p_servicios-left">Es como aprender jugando. Sacude tu celular, arma recompensas de gatitos, ¡sí gatitos! y más.</p>
      <Link className="btn-ver-mas" to="/cursos">Ver cursos</Link>
      </div>
    </div>
    <div className="servicios-grid-item">
      <div className="circle"></div>
      <div className="content">
      <h2 className="home_h2_servicios">Friends y Recompensas</h2>
      <p className="home_p_servicios">Ellos son nuestras empresas aliadas que ofrecerán sus recompensas cada que termines un curso.</p>
      <Link className="btn-ver-mas" to="/friends">Saber más</Link>
      </div>
    </div>
    <div className="servicios-grid-item">
      <div className="circle"></div>
      <div className="content-left">
      <h2 className="home_h2_servicios-left">Expertos</h2>
      <p className="home_p_servicios-left">Nuestro directorio de profesionales con los que puedes agendar citas, porque te duele la muela o no sabes hacer un contrato.</p>
      <Link className="btn-ver-mas" to="/expertos">Más info</Link>
      </div>
    </div>
    <div className="servicios-grid-item">
      <div className="circle"></div>
      <div className="content">
      <h2 className="home_h2_servicios">IA-INU</h2>
      <p className="home_p_servicios">Nuestro amigo te ayudará con todas tus dudas y consejos sobre la vida adulta.</p>
      <Link className="btn-ver-mas" to="/ia-inu">Conoce a IA-INU</Link>
      </div>
    </div>
  </div>
</section>

      <section className="friends-section">
        <h1 className="friends-section_texto">Conviertete en un <span className="special-text">Friend</span> para atraer <span className="special-text">más clientes.</span></h1>
        <p className="home_p_principal">Ellos ya ofrecen sus recompenesas en nuestra app.</p>
        <div className="friends-section-img">
          <img src="https://firebasestorage.googleapis.com/v0/b/making-friends-d7106.appspot.com/o/Logos%2FGloud%204startups%201.png?alt=media&token=adc458eb-ef73-497b-9c7e-1aa99cd94b0b" alt="Logo Google Cloud" />
          <img src="https://firebasestorage.googleapis.com/v0/b/making-friends-d7106.appspot.com/o/Logos%2Fvexi-logo-white.png?alt=media&token=a14cb996-0b10-4311-8dbd-6cda47930af6" alt="Logo Vexi" />
        </div>

        <div className="friends-section-img">
        <img src="https://firebasestorage.googleapis.com/v0/b/making-friends-d7106.appspot.com/o/Logos%2FTesicaf%C3%A9Blanco.png?alt=media&token=f33ed7d4-f242-4e10-b896-44d9917011e9" alt="Logo Tesicafé" />
          <img src="https://firebasestorage.googleapis.com/v0/b/making-friends-d7106.appspot.com/o/Logos%2FTuinLogoBlanco.png?alt=media&token=e393b543-bf4b-4a19-a074-bb63bea44347" alt="Logo Tuin" />

        </div>
      </section>


      <section className="final-section">
          <h1 className="final_texto">¿Ya sabes quién te va a <span className="special-text">enseñar a ser adulto</span>?</h1>
          <div className="contenedor_home_btn">
              <h1 className="home_h2">Descarga la app</h1>
              <a href="https://play.google.com/store/apps/details?id=com.makingfriends" className="home-app-link">
                <img src="https://firebasestorage.googleapis.com/v0/b/making-friends-d7106.appspot.com/o/Consumibles%2Fplay-logo.png?alt=media&token=98ad72aa-e73d-48f9-a13a-4c1aa784f1c0" alt="Descargar en Google Play" />
              </a>
              <a href="https://apps.apple.com/mx/app/making-friends/id6502792919" className="home-app-link">
                <img src="https://firebasestorage.googleapis.com/v0/b/making-friends-d7106.appspot.com/o/Consumibles%2Fapple-store.png?alt=media&token=a608c945-b9ea-450a-9529-97f20ebc6693" alt="Descargar en App Store" />
              </a>
            </div>
      </section>
    </>
  );
}

export default Home;
