import React from 'react';
import { BrowserRouter as Router, Switch, Route, useLocation } from 'react-router-dom';
import './App.css';
import Home from './components/Home.tsx';
import Header from './components/Header.tsx';
import Footer from './components/Footer.tsx';
import SEO from './components/SEO.tsx';
import Privacidad from './components/Privacidad.tsx';
import Terminos from './components/Terminos.tsx';
import IA_INU from './components/IA_INU.tsx';
import Friends from './components/Friends.tsx';
import ScrollToTop from './components/ScrollToTop.js';
import Cursos from './components/Cursos.tsx';
import Expertos from './components/Expertos.tsx';
import PaymentSuccess from './components/PaymentSuccess.tsx';

function App() {
  const location = useLocation();

  const hideHeaderFooter = location.pathname === '/payment';

  return (
    <div className="App">
      <SEO />
      {!hideHeaderFooter && <Header />}
      <Switch>
        <Route exact path="/" component={Home} />
        <Route path="/privacidad" component={Privacidad} />
        <Route path="/terminos" component={Terminos} />
        <Route path="/ia-inu" component={IA_INU} />
        <Route path="/friends" component={Friends} />
        <Route path="/cursos" component={Cursos} />
        <Route path="/expertos" component={Expertos} />
        <Route path="/payment" component={PaymentSuccess} />
      </Switch>
      {!hideHeaderFooter && <Footer />}
    </div>
  );
}

function AppWrapper() {
  return (
    <Router>
      <ScrollToTop />
      <App />
    </Router>
  );
}

export default AppWrapper;
