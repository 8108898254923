import React from 'react';
import { Helmet } from 'react-helmet';


function SEO() {
  return (
    <Helmet>
      <meta charSet="UTF-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <title>Making Friends: Aprende a ser adulto y gana recompensas</title>
      <meta name="description" content="Descubre Making Friends, la app interactiva y gratuita que te enseña a ser adulto mientras ganas increíbles recompensas. Aprende sobre finanzas, bienestar y mucho más." />
      <meta name="keywords" content="Making Friends, app, adulto, recompensas, finanzas, bienestar, desarrollo personal, desarrollo profesional, tesis, tarjeta de crédito, documentos importantes, premios, amigos, comunidad, colaboradores, Google Cloud, Vexi, CETIEM, Tesicafé" />
      <meta property="og:title" content="Making Friends: Aprende a ser adulto y gana recompensas" />
      <meta property="og:description" content="Descubre Making Friends, la app interactiva y gratuita que te enseña a ser adulto mientras ganas increíbles recompensas. Aprende sobre finanzas, bienestar y mucho más." />
      <meta property="og:image" content="https://firebasestorage.googleapis.com/v0/b/making-friends-d7106.appspot.com/o/Logos%2FLogoMNegro.png?alt=media&token=116e50a2-1abd-4f90-882b-d6cc16a7214d" />
      <meta property="og:url" content="https://www.makingfriends.com.mx/" />
      <meta property="og:type" content="website" />
      <meta property="og:locale" content="es_MX" />
      <link rel="stylesheet" href="css/home.css" />
      <link rel="stylesheet" href="css/footer.css" />
      <link rel="apple-touch-icon" sizes="180x180" href="assets/favicon/apple-touch-icon.png" />
      <link rel="icon" type="image/png" sizes="32x32" href="assets/favicon/favicon-32x32.png" />
      <link rel="icon" type="image/png" sizes="16x16" href="assets/favicon/favicon-16x16.png" />
      <link rel="manifest" href="assets/favicon/site.webmanifest" />
    </Helmet>
  );
}

export default SEO;
