import React from 'react';
import '../styles/Privacidad.css';

const Terminos: React.FC = () => {
  return (
    <>
      <div className="container">
        <main className="ticos_content">
        <h2 className="ticos_h2">ADVERTENCIA</h2>
<p className="ticos_p">Los siguientes términos y condiciones ("Condiciones Generales") regulan el acceso y uso de la aplicación móvil Making Friends ("Aplicación"), así como todos sus subdominios y subdirectorios asociados. Al descargar y utilizar la Aplicación, aceptas plenamente las Condiciones Generales establecidas en el momento de la descarga. Puedes consultar la versión más reciente de las Condiciones Generales en nuestra página de inicio. Si no aceptas las Condiciones Generales vigentes, debes abstenerte de descargar y utilizar la Aplicación.</p>

<h2 className="ticos_h2">OBJETO DE LAS CONDICIONES</h2>
<p className="ticos_p">1.1 Estas Condiciones Generales tienen como objetivo regular el acceso y uso de la Aplicación Making Friends. La Aplicación comprende la apariencia visual, características interactivas y todos los servicios proporcionados por Making Friends, incluyendo la enseñanza de habilidades para la vida adulta y la obtención de recompensas en forma de descuentos y productos y servicios ofrecidos por nuestras empresas asociadas ("Friends").</p>
<p className="ticos_p">1.2 Making Friends se reserva el derecho de modificar, en cualquier momento y sin previo aviso, la presentación, configuración, Contenidos y Servicios de la Aplicación. Al continuar utilizando la Aplicación después de cualquier modificación, aceptas las nuevas Condiciones Generales.</p>

<h2 className="ticos_h2">CONDICIONES PARTICULARES</h2>
<p className="ticos_p">2.1 El acceso y uso de ciertos Contenidos y Servicios de la Aplicación pueden estar sujetos a condiciones propias, avisos legales y reglamentos adicionales ("Condiciones Particulares"). Estas Condiciones Particulares prevalecerán sobre las Condiciones Generales en caso de contradicción y se incorporarán automáticamente a estas últimas.</p>

<h2 className="ticos_h2">ACCESO A LOS CONTENIDOS Y SERVICIOS</h2>
<p className="ticos_p">3.1 Para acceder a la Aplicación, debes descargarla desde una tienda de aplicaciones oficial y contar con un dispositivo móvil compatible. Eres responsable de los costos asociados a la descarga y conexión a Internet necesaria para utilizar la Aplicación. Algunos Contenidos y Servicios pueden requerir funcionalidades adicionales en tu dispositivo.</p>

<h2 className="ticos_h2">REGISTRO</h2>
<p className="ticos_p">4.1 Para acceder a determinados Contenidos y Servicios de la Aplicación, es posible que se te solicite crear una cuenta. Debes proporcionar información veraz y mantenerla actualizada. Making Friends se reserva el derecho de cancelar o denegar el acceso a aquellas cuentas con información falsa, inexacta o incompleta.</p>
<p className="ticos_p">4.2 Al registrarte, aceptas recibir comunicaciones de Making Friends relacionadas con la Aplicación, incluyendo actualizaciones, noticias y promociones.</p>

<h2 className="ticos_h2">FRIENDS O ALIADOS</h2>
<p className="ticos_p">5.1 Making Friends establece alianzas con diversas empresas y entidades, denominadas en este documento como "Friends" o "Aliados". Estos Friends ofrecen recompensas, servicios, productos y otros beneficios a los usuarios de Making Friends.</p>
<p className="ticos_p">5.2 Cada Friend tiene sus propios términos y condiciones de uso, políticas de privacidad y otros reglamentos que los usuarios deben aceptar y cumplir al acceder a sus servicios o beneficios a través de la Aplicación Making Friends.</p>
<p className="ticos_p">5.3 Making Friends no se hace responsable de las acciones, servicios, productos o contenidos proporcionados por sus Friends o Aliados. Cualquier reclamación, disputa o inconveniente relacionado con los servicios o productos ofrecidos por un Friend debe ser dirigido directamente a la entidad o empresa en cuestión.</p>
<p className="ticos_p">5.4 Al utilizar los servicios o beneficios ofrecidos por un Friend, el usuario reconoce y acepta que está sujeto a sus términos y condiciones y que Making Friends no tiene control ni responsabilidad sobre esos términos y condiciones.</p>

<h2 className="ticos_h2">NORMAS DE USO DE LA APLICACIÓN</h2>
<p className="ticos_p">6.1 Debes utilizar la Aplicación y sus Contenidos y Servicios de manera diligente, respetando las leyes, la moral, las buenas costumbres y los derechos de terceros. Queda prohibido utilizar la Aplicación para actividades ilegales, inmorales o que infrinjan los derechos de propiedad intelectual e industrial de Making Friends o de terceros.</p>
<p className="ticos_p">6.2 No debes interferir en el acceso y uso de la Aplicación por parte de otros usuarios, ni modificar, alterar, copiar, distribuir, transmitir, descompilar, desensamblar o realizar ingeniería inversa sobre la Aplicación o sus Contenidos y Servicios.</p>

<h2 className="ticos_h2">PRIVACIDAD Y PROTECCIÓN DE DATOS</h2>
<p className="ticos_p">7.1 Making Friends respeta tu privacidad y protege tus datos personales de acuerdo con nuestra Política de Privacidad. Al utilizar la Aplicación, aceptas que recopilemos, utilicemos y divulguemos tus datos personales según se describe en nuestra Política de Privacidad.</p>
<p className="ticos_p">7.2 Nos comprometemos a implementar medidas de seguridad para proteger tus datos personales y garantizar la confidencialidad de la información que nos proporcionas. Sin embargo, debes tener en cuenta que ninguna transmisión de datos a través de Internet puede garantizarse como completamente segura.</p>

<h2 className="ticos_h2">PROPIEDAD INTELECTUAL E INDUSTRIAL</h2>
<p className="ticos_p">8.1 Making Friends es titular de todos los derechos de propiedad intelectual e industrial sobre la Aplicación, sus Contenidos y Servicios, incluyendo, a título enunciativo pero no limitativo, el diseño, logotipos, marcas, nombres comerciales y cualquier otro elemento protegido por derechos de propiedad intelectual e industrial.</p>
<p className="ticos_p">8.2 Queda expresamente prohibida la reproducción, distribución, modificación o cualquier otro uso no autorizado de los elementos protegidos por derechos de propiedad intelectual e industrial sin el consentimiento previo y por escrito de Making Friends.</p>

<h2 className="ticos_h2">LIMITACIÓN DE RESPONSABILIDAD</h2>
<p className="ticos_p">9.1 El uso de la Aplicación es bajo tu propio riesgo. Making Friends no será responsable de ningún daño directo, indirecto, incidental, especial o consecuente que surja del acceso o uso de la Aplicación, incluyendo pérdida de datos, interrupciones en el servicio o cualquier otro perjuicio.</p>
<p className="ticos_p">9.2 No garantizamos la disponibilidad, precisión, integridad o seguridad de la Aplicación, sus Contenidos y Servicios. Nos esforzamos por brindar un servicio de calidad, pero no podemos garantizar que la Aplicación esté libre de errores o que funcione de manera ininterrumpida.</p>

<h2 className="ticos_h2">TERMINACIÓN Y MODIFICACIÓN</h2>
<p className="ticos_p">10.1 Making Friends se reserva el derecho de modificar o terminar la Aplicación, sus Contenidos y Servicios en cualquier momento y sin previo aviso. Tú puedes desinstalar la aplicación en cualquier momento. Al continuar utilizando la Aplicación después de cualquier modificación, aceptas las nuevas Condiciones Generales.</p>
<p className="ticos_p">10.2 En caso de incumplimiento de estas Condiciones Generales, Making Friends se reserva el derecho de suspender o cancelar tu acceso a la Aplicación.</p>

<h2 className="ticos_h2">LEY APLICABLE Y JURISDICCIÓN</h2>
<p className="ticos_p">11.1 Estas Condiciones Generales se rigen por las leyes de México. Cualquier disputa que surja de estas Condiciones Generales estará sujeta a la jurisdicción exclusiva de los tribunales competentes en México.</p>
<p className="ticos_p">Si tienes alguna pregunta o inquietud sobre estas condiciones generales, contáctanos en contacto@makingfriends.com.mx.</p>
<p className="ticos_p">Última actualización: [12/06/24]</p>
        </main>
      </div>
    </>
  );
};

export default Terminos;
