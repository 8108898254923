import React from 'react';
import '../styles/Privacidad.css';

const Privacidad: React.FC = () => {
  return (
    <>
      <div className="container">
        <main className="ticos_content">
          <h1 className="ticos_h1">Política de privacidad</h1>
          
          <p className="ticos_p">Making Friends reconoce y privilegia la privacidad y confidencialidad de cualquier información que tenga o llegue a tener en su poder, ya sea de manera temporal o definitiva, incluyendo cualquier dato o información personal. Making Friends reconoce que toda la información personal que se encuentra en su poder ha sido proporcionada de manera libre y voluntaria por sus titulares y/o ha sido adquirida de forma legítima a través de medios legales idóneos bajo el principio de privacidad y confidencialidad de la información, con el objetivo de garantizar la seguridad y protección de la misma y de sus titulares.</p>
          <p className="ticos_p">La información personal está constituida por datos que pueden ser utilizados exclusivamente para identificar o contactar a una sola persona. Se puede solicitar información personal en cualquier momento cuando se utilice la aplicación de Making Friends. Making Friends puede compartir información personal y utilizarla de acuerdo con la presente Política de Privacidad. Asimismo, podrá combinarla con otra información para proporcionar y mejorar sus productos, servicios, contenidos y publicidad.</p>
          <p className="ticos_p">De conformidad con la Ley de Protección de Datos Personales en Posesión de los Particulares, Making Friends, al procesar la información del usuario de la app, está obligado a observar los principios de licitud, consentimiento, información, calidad, finalidad, lealtad, proporcionalidad y responsabilidad previstos en la Ley.</p>
          <p className="ticos_p">Se mantienen políticas, medidas de seguridad física, electrónica y de procedimientos para proteger la confidencialidad de la información que se obtiene sobre los usuarios de la app.</p>
          <p className="ticos_p">A continuación se incluyen algunos ejemplos de información personal que Making Friends puede recopilar y cómo la utiliza. Estos ejemplos son meramente ilustrativos y no deben considerarse como una descripción exhaustiva de sus prácticas de recopilación y divulgación de información.</p>

          <h2 className="ticos_h2">¿QUÉ INFORMACIÓN SE RECOLECTA?</h2>
          <p className="ticos_p">Cuando utilizas la app de Making Friends, podemos recopilar algunos datos personales como tu nombre, dirección, número de teléfono, dirección de correo electrónico, ID de Facebook, Twitter y/o Linkedin. Además, en caso de realizar una compra dentro de la app, se podrán solicitar tus datos personales como nombre, correo electrónico, datos de tu tarjeta de crédito o débito, o bien, tu comprobante de pago, según sea la opción de pago que hayas elegido. Cabe destacar que nuestra app está diseñada para ser utilizada por personas mayores de edad, por lo tanto, solicitamos que los menores de edad no proporcionen información personal sin el consentimiento de sus padres o tutores.</p>

          <h2 className="ticos_h2">USUARIOS REGISTRADOS</h2>
          <p className="ticos_p">Para interactuar dentro de la app, los usuarios registrados deberán utilizar una cuenta de correo electrónico y una contraseña que los identificará. Los usuarios registrados deben mantener su contraseña bajo absoluta confidencialidad y en ningún caso revelar o compartirla con otras personas. El usuario será responsable de todos los actos que se realicen mediante el uso de su cuenta de correo electrónico y contraseña.</p>

          <h2 className="ticos_h2">¿CÓMO UTILIZAMOS TU INFORMACIÓN PERSONAL?</h2>
          <p className="ticos_p">Proveer los servicios y/o productos solicitados por ti, notificarte sobre nuevos servicios o productos relacionados, comunicarte cambios en los mismos, confirmar y procesar las transacciones de compra, mantener contacto contigo o responder a tus preguntas de servicio al cliente.</p>
          <p className="ticos_p">La facturación de los servicios prestados, en caso de ser aplicable. Mejorar y personalizar tu experiencia de usuario, así como comunicarnos contigo para fines de marketing y promoción de nuestros servicios, productos o eventos, realizar estudios y programas necesarios para determinar hábitos, enviar publicidad, boletines informativos, mensajes telefónicos o correos electrónicos personalizados, así como mejorar la efectividad de nuestra app y actividades comerciales.</p>
          <p className="ticos_p">Realizar evaluaciones periódicas de la calidad de nuestros productos y servicios, auditorías, análisis e investigaciones para el desarrollo y mejora de nuestros servicios, productos, contenidos, comunicaciones y publicidad, y en general, para cumplir con las obligaciones que hemos contraído contigo.</p>
          <p className="ticos_p">Transferir tus datos a terceros para la contratación de determinados productos o servicios, los cuales deberán ser autorizados previamente por ti y de acuerdo a tus necesidades. Making Friends se pondrá en contacto contigo en caso de que sea necesario aplicar esta cláusula, en el marco de las actividades de Making Friends.</p>

          <h2 className="ticos_h2">INFORMACIÓN EN LÍNEA Y RECOLECCIÓN DE DATOS</h2>
          <p className="ticos_p">Puedes utilizar nuestra app sin necesidad de identificarte o revelar información personal, incluyendo tu dirección de correo electrónico. En este caso, recopilamos información estadística sobre el rendimiento de la app y para facilitar su uso. También podemos recopilar estadísticas sobre nuestros usuarios, ventas, patrones de uso y servicios, y compartirlas con terceros. Sin embargo, al revelar estas estadísticas a terceros, no incluiremos datos personales de identificación, a menos que hayas acordado recibir información personalizada. Además, dentro de nuestra app, podrías proporcionarnos información personal para fines específicos, como registrarte en nuestra app, contactarnos, interactuar con terceros de tu interés y realizar compras de nuestros productos y/o servicios. La información recopilada podría incluir tu nombre, dirección, teléfono, dirección de correo electrónico y contraseña.</p>

          <h2 className="ticos_h2">COMUNICACIONES POR CORREO ELECTRÓNICO</h2>
          <p className="ticos_p">En ocasiones, podemos enviarte correos electrónicos con información que podría resultarte útil, incluyendo información sobre nuestros productos y servicios.</p>

          <h2 className="ticos_h2">MÉTODOS DE SEGURIDAD UTILIZADOS EN NUESTRA APP</h2>
          <p className="ticos_p">Implementamos medidas de seguridad como encriptación y autenticación para proteger la información que recopilamos en nuestra app, con el objetivo de garantizar su confidencialidad y seguridad. Sin embargo, debes tener en cuenta que los correos electrónicos que recibas fuera de nuestra app pueden no estar protegidos.</p>
          <p className="ticos_p">No obstante lo anterior, Making Friends no se hace responsable por interceptaciones ilegales o violación de sus sistemas o bases de datos por parte de personas no autorizadas. Making Friends tampoco se hace responsable por la indebida utilización de la información obtenida por esos medios.</p>

          <h2 className="ticos_h2">REVELACIÓN DE INFORMACIÓN A PROVEEDORES DE SERVICIO</h2>
          <p className="ticos_p">Podemos revelar información personal recopilada a terceros, como empresas y proveedores de servicios relacionados con los servicios en los que has mostrado interés en Making Friends. En todos los casos, requerimos a los proveedores de servicios a quienes revelamos información personal que cumplan con nuestra Política de Privacidad y utilicen la información solamente para los fines para los que han sido contratados. Revelamos información personal a estos proveedores con el fin de brindarte un mejor servicio y en cumplimiento de las actividades de Making Friends.</p>

          <h2 className="ticos_h2">REVELACIÓN DE OTRA INFORMACIÓN PERMITIDA POR LA LEY</h2>
          <p className="ticos_p">Making Friends puede divulgar información personal en virtud de una ley, procedimiento judicial, administrativo, acción legal y/o solicitada por autoridades. También puede divulgar información personal si considera que, por razones de seguridad nacional, cumplimiento de las leyes u otras cuestiones de importancia nacional, dicha divulgación es necesaria o conveniente.</p>

          <h2 className="ticos_h2">PROHIBICIÓN DE REVELAR INFORMACIÓN PARA OTROS FINES</h2>
          <p className="ticos_p">No compartiremos tu información personal con compañías externas para fines que no sean los descritos anteriormente. Por ejemplo, no venderemos información personal a compañías externas que deseen ofrecerte sus productos y servicios sin tu previa autorización, así como sin una relación comercial previa entre las compañías y Making Friends. Podemos ofrecerte productos o servicios en representación de otras compañías, pero no revelaremos tus datos personales sin tu consentimiento previo.</p>

          <h2 className="ticos_h2">SEGURIDAD DE LA INFORMACIÓN RECOPILADA</h2>
          <p className="ticos_p">Mantenemos estrictas medidas de seguridad aplicables para proteger tu información personal contra el acceso no autorizado o indebido. El acceso a la información personal está restringido a los empleados y proveedores de servicios que necesiten conocerla para fines comerciales legítimos. Los empleados y proveedores de servicios que incumplan nuestra Política de Privacidad estarán sujetos a medidas disciplinarias y/o sanciones correspondientes.</p>

          <h2 className="ticos_h2">CAMBIOS EN ESTA POLÍTICA DE PRIVACIDAD</h2>
          <p className="ticos_p">Las modificaciones a esta política estarán disponibles en la app de Making Friends. Te recomendamos que verifiques esta Política de Privacidad periódicamente para mantenerte informado sobre cualquier cambio.</p>
          <p className="ticos_p">Nos comprometemos a proteger tu información personal y utilizarla o compartirla únicamente para cumplir con las obligaciones derivadas de esta Política de Privacidad. Al no oponerte activamente a los términos de esta Política de Privacidad, prestas tu consentimiento para la distribución de información personal o los informes de consumo según se describe anteriormente.</p>
          <p className="ticos_p">Las prácticas y políticas incluidas en esta Política de Privacidad reemplazan todas las notificaciones o manifestaciones anteriores relacionadas con el tema.</p>
          <p className="ticos_p">Esta Política de Privacidad se aplica exclusivamente a las prácticas de recopilación, protección, utilización y transferencia de información revelada a Making Friends para los fines mencionados.</p>

        </main>
      </div>
    </>
  );
};

export default Privacidad;
